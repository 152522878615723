import React, { useCallback, useEffect, useState } from "react";
import styles from "./styles.module.css";
import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
} from "src/@/components/ui/table";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "src/@/components/ui/tabs";
import { AddCreators } from "src/components/designSystem/organisms/AddCreators";
import { getPages } from "src/api/pages";
import { getUserInfo } from "src/api/login";
import { Link } from "react-router-dom";
import {
  getConversations,
  getFBCreatorConversation,
  getTaggedFBPosts,
} from "src/api/conversations";
import { getFBConversations, getTaggedPosts } from "src/utils/FbApiHandlers";
import moment from "moment";
import { getCreators } from "src/api/creators";
import { ChatBox } from "src/components/designSystem/organisms/ChatView";
import { toast } from "react-toastify";
import classNames from "classnames";
const fallbackPic =
  "https://www.pngitem.com/pimgs/m/146-1468479_my-profile-icon-blank-profile-picture-circle-hd.png";

export const NewDashboard: React.FC = () => {
  const [userDetail, setUserDetail] = useState<{
    _id: string;
    accessToken: string;
    name: string;
    instagramId: string;
    password: string;
    username: string;
    createdAt: string;
    updatedAt: string;
  }>({
    _id: "",
    accessToken: "",
    name: "",
    instagramId: "",
    password: "",
    username: "",
    createdAt: "",
    updatedAt: "",
  });
  const [pages, setPages] = useState<
    {
      _id: string;
      userId: string;
      accessToken: string;
      category: string;
      name: string;
      pageId: string;
      createdAt: string;
      updatedAt: string;
      connectedId: string;
      about: string;
      following: string;
      followers: string;
      picture: string;
      username: string;
    }[]
  >([]);
  const [selected, setSelected] = useState("");
  const [conversations, setConversations] = useState([]);
  const [creators, setCreators] = useState([]);
  const [isLogin, setisLogin] = useState(false);
  const [tagged, setTagged] = useState([]);

  // const getConversations = useCallback(() => {
  //   console.log("getConversations");
  // }, []);

  useEffect(() => {
    getPages().then((res: any) => {
      if (res) {
        setPages(res.data);
        setSelected(res.data[0]._id);
        getPageCreators();
      }
    });
    getUserInfo().then((res: any) => {
      if (res) {
        setUserDetail(res);
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getUserPageInfo = () => {
    if (typeof FB !== "undefined" && typeof FB === "object") {
      FB.getLoginStatus((response) => {
        if (response.status === "connected") {
          setisLogin(true);
        }
      });
    }
  };

  useEffect(() => {
    getUserPageInfo();
  }, []);

  const getPageConversations = useCallback(() => {
    if (pages[0]) {
      getFBCreatorConversation({ token: pages[0].accessToken }).then(
        (response: any) => {
          const tempConversations = response.data.reduce(
            (acc: any[], x: any) => {
              const stories = x.messages.data.filter(
                (msg: any) => msg.story !== undefined
              );
              if (stories) {
                return [...acc, ...stories];
              }
              return acc;
            },
            []
          );
          const conversations = tempConversations
            .map((x: any) => {
              const creator: any = creators.find(
                (creator: any) => creator.username === x.from.username
              );
              const payload: any = {
                media: x.story.mention.link,
                messageCreatedAt: x.created_time,
                messageType: "story",
                name: "",
                username: "",
                profilePic: "",
                followers: 0,
              };
              if (creator) {
                payload["name"] = creator.name;
                payload["username"] = creator.username;
                payload["profilePic"] = creator.profilePic;
                payload["followers"] = creator.followers;
              }

              return payload;
            })
            .filter((x: any) => x.name !== "");
          setConversations(conversations);
        }
      );
    }
  }, [pages, creators]);

  const getPageCreators = useCallback(() => {
    getCreators().then((res: any) => {
      if (res) {
        setCreators(res.data);
        getPageConversations();
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getPageTaggedPosts = useCallback(() => {
    if (pages && pages[0]) {
      getTaggedFBPosts(pages[0].connectedId, pages[0].pageId).then(
        (res: any) => {
          setTagged(res);
        }
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pages]);

  useEffect(() => {
    getPageTaggedPosts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  const refreshConversations = async () => {
    toast.info("fetching ...");
    const page: any = pages.find((page: any) => page._id === selected);
    await getFBConversations(page.pageId, page.accessToken, selected);
    await getPageTaggedPosts();
    setTimeout(async () => {
      await getPageConversations();
    }, 3000);
  };

  const page = pages?.find((page: any) => page._id === selected);

  return (
    <div className={styles.wrapper}>
      <div
        className={classNames(
          "flex justify-center w-full",
          styles.profileWrapper
        )}
      >
        {page && isLogin ? (
          <div
            className={classNames(
              "p-4 bg-white rounded shadow-md",
              styles.profileBlock
            )}
          >
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <img
                  src={page.picture}
                  alt="User Profile"
                  className="w-16 h-16 mr-4 rounded-full"
                />
                <div>
                  <h2 className="text-2xl font-semibold">{page.name}</h2>
                  <a
                    className="text-gray-600"
                    href={`https://instagram.com/${
                      page?.username || page.name
                    }`}
                  >
                    @{page?.username || page.name}
                  </a>
                </div>
              </div>

              <div className="flex">
                <div className="mr-4">
                  <p className="font-semibold">Followers</p>
                  <p className="text-gray-700">{page.followers}</p>
                </div>
                <div>
                  <p className="font-semibold">Following</p>
                  <p className="text-gray-700">{page.following}</p>
                </div>
              </div>
            </div>

            <div className="mt-4">
              <p className="text-gray-700">{page.about}</p>
            </div>
          </div>
        ) : null}
      </div>

      {userDetail.accessToken.length !== 0 && (
        <section className={styles.headerBlock}>
          <p className={styles.pageTitle}>Story Tracker</p>
          <AddCreators
            triggerText="Add Creator"
            callback={() => {
              getPageConversations();
              getPageCreators();
            }}
            token={pages[0] ? pages[0]?.accessToken : ""}
          />
        </section>
      )}
      {userDetail?.accessToken.length ? (
        <section className={styles.mainBlock}>
          <Tabs className="TabsRoot" defaultValue="tab1">
            <TabsList
              className={styles.TabsList}
              aria-label="Manage your account"
            >
              <TabsTrigger className="TabsTrigger" value="tab1">
                Mentions
              </TabsTrigger>
              <TabsTrigger className="TabsTrigger" value="tab2">
                Creators
              </TabsTrigger>
              <TabsTrigger className="TabsTrigger" value="tab3">
                Chat
              </TabsTrigger>
            </TabsList>

            <TabsContent className="TabsContent" value="tab1">
              {userDetail?.accessToken && (
                <div className={styles.contentHeaderBlock}>
                  <select
                    value={selected}
                    onChange={(e) => setSelected(e.target.value)}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  >
                    {pages &&
                      pages.map((page: any, i: number) => (
                        <option value={page?._id} key={i}>
                          {page?.name}
                        </option>
                      ))}
                  </select>
                  <button
                    className={styles.primaryButton}
                    onClick={refreshConversations}
                  >
                    Refresh
                  </button>
                </div>
              )}
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableCell className="font-bold">Creator's Name</TableCell>
                    <TableCell className="font-bold">Username</TableCell>
                    <TableCell className="font-bold">Followers</TableCell>
                    <TableCell className="font-bold">Preview</TableCell>
                    <TableCell className="font-bold">Type</TableCell>
                    <TableCell className="font-bold">
                      Story/Post Time Stamp
                    </TableCell>
                  </TableRow>
                </TableHeader>

                <TableBody>
                  {tagged &&
                    tagged.map((x: any, i: number) => (
                      <TableRow key={i}>
                        <TableCell className="w-40">
                          <div className="flex">
                            <object
                              data={x.from.profilePicture}
                              type="image/png"
                              className="w-10 h-10 mr-2 rounded-full"
                            >
                              <img
                                className="w-10 h-10 mr-2 rounded-full"
                                src={fallbackPic}
                                alt={`${x.from.name} profile pic`}
                              />
                            </object>
                            <p className="flex items-center">{x.from.name}</p>
                          </div>
                        </TableCell>
                        <TableCell className="w-20">
                          {x.from.username}
                        </TableCell>
                        <TableCell width={50}>
                          {x.from.followers ? x.from.followers : "-"}
                        </TableCell>
                        <TableCell width={50}>
                          <Link
                            to={x?.media}
                            target="_blank"
                            className="text-blue-800 underline"
                          >
                            Link
                          </Link>
                        </TableCell>
                        <TableCell width={50}>{x?.messageType}</TableCell>
                        <TableCell width={50}>
                          {moment(x?.messageCreatedAt).format(
                            "DD-MM-YYYY hh:mm:a"
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  {conversations?.reverse()?.map((convo: any, i: number) => (
                    <TableRow key={i}>
                      <TableCell className="w-40">
                        <div className="flex">
                          <object
                            data={
                              convo.profilePic ? convo.profilePic : fallbackPic
                            }
                            type="image/png"
                            className="w-10 h-10 mr-2 rounded-full"
                          >
                            <img
                              className="w-10 h-10 mr-2 rounded-full"
                              src={fallbackPic}
                              alt={`${convo?.name} profile pic`}
                            />
                          </object>
                          <p className="flex items-center">{convo?.name}</p>
                        </div>
                      </TableCell>
                      <TableCell className="w-20">{convo?.username}</TableCell>
                      <TableCell width={50}>
                        {convo?.followers ? convo?.followers : "-"}
                      </TableCell>
                      <TableCell width={50}>
                        {convo?.media.length ? (
                          <Link
                            to={convo?.media}
                            target="_blank"
                            className="text-blue-800 underline"
                          >
                            Link
                          </Link>
                        ) : (
                          <span title="Story expired">
                            🕥 Mentioned you in their story
                          </span>
                        )}
                      </TableCell>
                      <TableCell width={50}>{convo?.messageType}</TableCell>
                      <TableCell width={50}>
                        {moment(convo?.messageCreatedAt).format(
                          "DD-MM-YYYY hh:mm:a"
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TabsContent>
            <TabsContent className="TabsContent" value="tab2">
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableCell className="font-bold">Creator's Name</TableCell>
                    <TableCell className="font-bold">Username</TableCell>
                    <TableCell className="font-bold">Followers</TableCell>
                  </TableRow>
                </TableHeader>

                <TableBody>
                  {creators
                    ?.filter((x: any) => x.username?.length > 0)
                    ?.map((creator: any, i: number) => (
                      <TableRow key={i}>
                        <TableCell>
                          <div className="flex">
                            <object
                              data={creator.profilePic}
                              type="image/png"
                              className="w-10 h-10 mr-2 rounded-full"
                            >
                              <img
                                className="w-10 h-10 mr-2 rounded-full"
                                src={fallbackPic}
                                alt={`${creator.name} profile pic`}
                              />
                            </object>
                            <p className="flex items-center">{creator.name}</p>
                          </div>
                        </TableCell>
                        <TableCell>{creator?.username}</TableCell>
                        <TableCell>{creator?.followers}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TabsContent>
            <TabsContent className="TabsContent" value="tab3">
              <ChatBox />
            </TabsContent>
          </Tabs>
        </section>
      ) : (
        <section className={styles.footerBlock}>
          <p className={styles.pageFooterTitle}>Welcome to Story Tracker</p>
          <p className={styles.pageFooterSubTitle}>
            Please connect your instagram. <Link to="/connect">click here</Link>{" "}
          </p>
        </section>
      )}
    </div>
  );
};
