import { AxiosError } from "axios";
import client from "./axios";
const ENV = `${process.env.REACT_APP_BACKEND_URL}/users`;

export const login = async (username: string, password: string) => {
  try {
    const login = await client.post(`${ENV}/login`, {
      username,
      password,
    });
    return login.data;
  } catch (error: AxiosError | any) {
    console.log("login----", error?.message as string);
  }
};

export const logout = async () => {
  await localStorage.removeItem("token");
  window.location.assign("/");
};

export const updateUserInfo = async (data: any) => {
  try {
    const token = localStorage.getItem("token");
    const update = await client.put(`${ENV}/update`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return update.data;
  } catch (error: AxiosError | any) {
    console.log("updateUserInfo----", error?.message as string);
  }
};

export const insertFBUser = async (data: any) => {
  try {
    const token = localStorage.getItem("token");
    const update = await client.post(`${ENV}/scrapped`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return update.data;
  } catch (error: AxiosError | any) {
    console.log("updateUserInfo----", error?.message as string);
  }
};

export const getUserInfo = async () => {
  try {
    const token = localStorage.getItem("token");
    const userInfo = await client.get(`${ENV}/me`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return userInfo.data;
  } catch (error: AxiosError | any) {
    console.log("getUserInfo----", error?.message as string);
  }
};
