import { toast } from "react-toastify";
import client from "./axios";

const ENV = `${process.env.REACT_APP_BACKEND_URL}/conversations`;

export const getConversations = async (selected: string = "") => {
  try {
    const conversations = await client.get(`${ENV}?page=${selected}`);
    return conversations;
  } catch (error: any) {
    console.log("addConversation----", error?.message as string);
  }
};

export const addConversation = async ({
  conversationId,
  pageId,
  storyName,
  messageType,
  from,
  to,
  message,
  media,
  mediaId,
  messageCreatedAt,
  participants,
}: {
  conversationId: string;
  pageId: string;
  from: {
    id: string;
    username: string;
  };
  to: {
    id: string;
    username: string;
  };
  storyName: string;
  messageType: string;
  message: string;
  media: string;
  mediaId: string;
  messageCreatedAt: string;
  participants: {
    id: string;
    username: string;
  }[];
}) => {
  try {
    const conversation = await client.post(`${ENV}/add`, {
      conversationId,
      pageId,
      storyName,
      messageType,
      message,
      media,
      mediaId,
      from,
      to,
      messageCreatedAt,
      participants,
    });
    return conversation;
  } catch (error: any) {
    console.log("addConversation----", error?.message as string);
  }
};

export const getCreatorConversation = async ({
  creatorId,
}: {
  creatorId: string;
}) => {
  try {
    const conversation = await client.get(`${ENV}/creator/${creatorId}`);
    return conversation;
  } catch (error: any) {
    console.log("addConversation----", error?.message as string);
  }
};

export const getFBCreatorConversation = async ({
  token,
}: {
  token: string;
}) => {
  try {
    const messages = new Promise((resolve, reject) => {
      FB.api(
        `/291074118138/conversations`,
        "get",
        {
          fields: `id,name,updated_time,messages{story,tags,message,created_time,from,to,attachments},participants`,
          platform: "instagram",
          access_token: token,
        },
        function (response: any) {
          if (response && !response.error) {
            resolve(response);
          } else {
            reject(response.error);
          }
        }
      );
    });
    return messages;
  } catch (error: any) {
    console.log("getFBCreatorConversation----", error?.message as string);
  }
};

export const getCreatorProfile = async (
  connectedId: string,
  username: string
) => {
  return await new Promise((resolve, reject) => {
    FB.api(
      `${connectedId}?fields=business_discovery.username(${username}){username,name,profile_picture_url,id,ig_id,followers_count}`,
      (profile: any) => {
        if (!profile.error) {
          resolve(profile);
        } else {
          toast.error("Profile not found");
          reject("No profile data for post");
        }
      }
    );
  });
};

export const getTaggedFBPosts = async (connectedId: string, pageId: string) => {
  const response: any = await new Promise((resolve, reject) => {
    FB.api(
      `/${connectedId}`,
      "get",
      {
        fields:
          "username,name,biography,tags{caption,comments_count,id,like_count,media_type,permalink,thumbnail_url,timestamp,username,media_url}",
        platform: "instagram",
      },
      (response: any) => {
        if (response) {
          resolve(response);
        } else {
          reject("No response from Facebook API");
        }
      }
    );
  });

  if (response?.tags?.data?.length > 0) {
    const promises = response.tags.data.map(async (post: any) => {
      const profile: any = await new Promise((resolve, reject) => {
        FB.api(
          `${connectedId}?fields=business_discovery.username(${post.username}){username,name,profile_picture_url,followers_count}`,
          (profile: any) => {
            if (profile) {
              resolve(profile);
            } else {
              reject("No profile data for post");
            }
          }
        );
      });

      const payload = {
        conversationId: post.id,
        participants: [
          {
            username: post.username,
            id: profile.business_discovery.id,
          },
        ],
        from: {
          id: profile.business_discovery.id,
          username: post.username,
          name: profile.business_discovery.name,
          profilePicture: profile.business_discovery.profile_picture_url,
          followers: profile.business_discovery.followers_count,
        },
        to: {
          id: pageId,
          username: response.username,
        },
        storyName: "",
        messageType: post.media_type === "VIDEO" ? "reel" : "post",
        message: post.caption,
        media: post.permalink,
        mediaId: post.id,
        messageCreatedAt: post.timestamp,
      };

      return payload;
    });

    return await Promise.all(promises);
  }
};
