import {
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  ScriptableContext,
  Title,
  Tooltip,
} from "chart.js";
import React from "react";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

type Props = {
  width?: string;
  height?: string;
};

export const LineChart: React.FC<Props> = ({
  width = "100%",
  height = "100%",
}) => {
  const data = () => {
    return {
      labels: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      datasets: [
        {
          label: "",
          data: [33, 53, 85, 41, 44, 65, 33, 53, 85, 41, 44, 65],
          fill: "start",
          backgroundColor: (context: ScriptableContext<"line">) => {
            const ctx = context.chart.ctx;
            const gradient = ctx.createLinearGradient(
              0,
              0,
              0,
              ctx.canvas.width / 2.5
            );
            gradient.addColorStop(0, "rgba(182, 146, 246,1)");
            gradient.addColorStop(1, "rgba(182, 146, 246,0)");
            return gradient;
          },
          borderColor: "rgba(127, 86, 217, 1)",
        },
      ],
    };
  };

  const options = {
    responsive: true,
    elements: {
      line: {
        tension: 0.35,
      },
    },
    plugins: {
      legend: {
        position: "top" as const,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        display: true,
        title: {
          display: true,
          text: "Views count",
        },
      },
      x: {
        display: true,
        title: {
          display: true,
          text: "Days",
        },
      },
    },
  };

  return <Line data={data()} options={options} width={width} height={height} />;
};
