import React from "react";

import styles from "./styles.module.css";

type Props = {
  children: React.ReactNode;
  marginTop?: string;
};

export const Layout: React.FC<Props> = ({ children, marginTop = "100px" }) => {
  return (
    <div
      className={styles.layout}
      style={{
        marginTop,
      }}
    >
      {children}
    </div>
  );
};
