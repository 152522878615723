export const Logo = () => {
  return (
    <svg
      width="58"
      height="58"
      viewBox="0 0 58 58"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_dd_77_24509)">
        <g clipPath="url(#clip0_77_24509)">
          <rect
            x="5.25195"
            y="3.4707"
            width="48"
            height="48"
            rx="12"
            fill="white"
          />
          <rect
            x="5.25195"
            y="3.4707"
            width="48"
            height="48"
            rx="12"
            fill="url(#paint0_linear_77_24509)"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M29.2521 6.52861C17.6863 6.52861 8.31035 15.9046 8.31035 27.4704C8.31035 39.0362 17.6863 48.4121 29.2521 48.4121C40.8179 48.4121 50.1939 39.0362 50.1939 27.4704C50.1939 15.9046 40.8179 6.52861 29.2521 6.52861ZM8.19385 27.4704C8.19385 15.8402 17.6219 6.41211 29.2521 6.41211C40.8823 6.41211 50.3104 15.8402 50.3104 27.4704C50.3104 39.1005 40.8823 48.5286 29.2521 48.5286C17.6219 48.5286 8.19385 39.1005 8.19385 27.4704Z"
            fill="#D0D5DD"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M29.252 21.5872C26.0026 21.5872 23.3685 24.2213 23.3685 27.4707C23.3685 30.7201 26.0026 33.3542 29.252 33.3542C32.5013 33.3542 35.1354 30.7201 35.1354 27.4707C35.1354 24.2213 32.5013 21.5872 29.252 21.5872ZM23.252 27.4707C23.252 24.157 25.9382 21.4707 29.252 21.4707C32.5657 21.4707 35.252 24.157 35.252 27.4707C35.252 30.7844 32.5657 33.4707 29.252 33.4707C25.9382 33.4707 23.252 30.7844 23.252 27.4707Z"
            fill="#D0D5DD"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M29.2519 23.4505C27.032 23.4505 25.2325 25.25 25.2325 27.4699C25.2325 29.6898 27.032 31.4893 29.2519 31.4893C31.4718 31.4893 33.2713 29.6898 33.2713 27.4699C33.2713 25.25 31.4718 23.4505 29.2519 23.4505ZM25.116 27.4699C25.116 25.1857 26.9677 23.334 29.2519 23.334C31.5361 23.334 33.3878 25.1857 33.3878 27.4699C33.3878 29.7541 31.5361 31.6058 29.2519 31.6058C26.9677 31.6058 25.116 29.7541 25.116 27.4699Z"
            fill="#D0D5DD"
          />
          <path
            d="M29.1938 3.4707H29.3104V51.4707H29.1938V3.4707Z"
            fill="#D0D5DD"
          />
          <path
            d="M53.252 27.4121L53.252 27.5286L5.25195 27.5286L5.25195 27.4121L53.252 27.4121Z"
            fill="#D0D5DD"
          />
          <path
            d="M45.155 3.4707H45.2715V51.4707H45.155V3.4707Z"
            fill="#D0D5DD"
          />
          <path
            d="M21.2134 3.4707H21.3299V51.4707H21.2134V3.4707Z"
            fill="#D0D5DD"
          />
          <path
            d="M37.1743 3.4707H37.2908V51.4707H37.1743V3.4707Z"
            fill="#D0D5DD"
          />
          <path
            d="M13.2324 3.4707H13.3489V51.4707H13.2324V3.4707Z"
            fill="#D0D5DD"
          />
          <path
            d="M53.252 43.373L53.252 43.4896L5.25195 43.4895L5.25195 43.373L53.252 43.373Z"
            fill="#D0D5DD"
          />
          <path
            d="M53.252 19.4316L53.252 19.5481L5.25195 19.5481L5.25195 19.4316L53.252 19.4316Z"
            fill="#D0D5DD"
          />
          <path
            d="M53.252 35.3926L53.252 35.5091L5.25195 35.5091L5.25195 35.3926L53.252 35.3926Z"
            fill="#D0D5DD"
          />
          <path
            d="M53.252 11.4512L53.252 11.5677L5.25195 11.5677L5.25195 11.4512L53.252 11.4512Z"
            fill="#D0D5DD"
          />
          <g filter="url(#filter1_dd_77_24509)">
            <circle
              cx="29.252"
              cy="27.4707"
              r="12"
              fill="url(#paint1_linear_77_24509)"
            />
          </g>
          <g filter="url(#filter2_b_77_24509)">
            <path
              d="M5.25195 27.4707H53.252V32.2707C53.252 38.9913 53.252 42.3516 51.944 44.9186C50.7936 47.1765 48.9578 49.0123 46.6998 50.1628C44.1329 51.4707 40.7726 51.4707 34.052 51.4707H24.452C17.7313 51.4707 14.371 51.4707 11.8041 50.1628C9.54612 49.0123 7.71036 47.1765 6.55987 44.9186C5.25195 42.3516 5.25195 38.9913 5.25195 32.2707V27.4707Z"
              fill="white"
              fillOpacity="0.2"
            />
          </g>
        </g>
        <rect
          x="5.47695"
          y="3.6957"
          width="47.55"
          height="47.55"
          rx="11.775"
          stroke="#D0D5DD"
          strokeWidth="0.45"
        />
      </g>
      <defs>
        <filter
          id="filter0_dd_77_24509"
          x="0.751953"
          y="0.470703"
          width="57"
          height="57"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1.5" />
          <feGaussianBlur stdDeviation="1.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.06 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_77_24509"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1.5" />
          <feGaussianBlur stdDeviation="2.25" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_77_24509"
            result="effect2_dropShadow_77_24509"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_77_24509"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_dd_77_24509"
          x="12.752"
          y="12.4707"
          width="33"
          height="33"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1.5" />
          <feGaussianBlur stdDeviation="1.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.06 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_77_24509"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1.5" />
          <feGaussianBlur stdDeviation="2.25" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_77_24509"
            result="effect2_dropShadow_77_24509"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_77_24509"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_b_77_24509"
          x="-2.24805"
          y="19.9707"
          width="63"
          height="39"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="3.75" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_77_24509"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_77_24509"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_77_24509"
          x1="29.252"
          y1="3.4707"
          x2="29.252"
          y2="51.4707"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#D0D5DD" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_77_24509"
          x1="23.252"
          y1="39.4707"
          x2="35.252"
          y2="15.4707"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#53389E" />
          <stop offset="1" stopColor="#6941C6" />
        </linearGradient>
        <clipPath id="clip0_77_24509">
          <rect
            x="5.25195"
            y="3.4707"
            width="48"
            height="48"
            rx="12"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
