import client from "./axios";

const ENV = `${process.env.REACT_APP_BACKEND_URL}/pages`;

export const getPages = async () => {
  try {
    const pages = await client.get(`${ENV}`);
    return pages;
  } catch (error: any) {
    console.log("getPages----", error?.message as string);
  }
};

export const addPage = async ({
  accessToken,
  category,
  name,
  pageId,
  connectedId,
  followers,
  following,
  about,
  picture,
  username
}: {
  accessToken: string;
  category: string;
  name: string;
  pageId: string;
  connectedId: string;
  followers: string;
  following: string;
  about: string;
  picture: string;
  username: string;
}) => {
  try {
    const page = await client.post(`${ENV}/add`, {
      accessToken,
      category,
      name,
      pageId,
      connectedId,
      followers,
      following,
      about,
      picture,
      username
    });
    return page;
  } catch (error: any) {
    console.log("addPage----", error?.message as string);
  }
};
