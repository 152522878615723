import React from "react";

export const SendIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M8.74952 11.2501L17.4995 2.50014M8.85584 11.5235L11.0459 17.1552C11.2389 17.6513 11.3353 17.8994 11.4743 17.9718C11.5948 18.0346 11.7384 18.0347 11.859 17.972C11.998 17.8998 12.0948 17.6518 12.2883 17.1559L17.7803 3.08281C17.955 2.63516 18.0423 2.41133 17.9945 2.26831C17.953 2.1441 17.8556 2.04663 17.7314 2.00514C17.5883 1.95736 17.3645 2.0447 16.9169 2.21939L2.84373 7.71134C2.34784 7.90486 2.09989 8.00163 2.02763 8.14071C1.96499 8.26129 1.96508 8.40483 2.02786 8.52533C2.10028 8.66433 2.34834 8.7608 2.84446 8.95373L8.47613 11.1438C8.57684 11.183 8.62719 11.2026 8.66959 11.2328C8.70717 11.2596 8.74004 11.2925 8.76685 11.3301C8.79709 11.3725 8.81667 11.4228 8.85584 11.5235Z"
        stroke="white"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
