import { toast } from "react-toastify";
import { addConversation } from "src/api/conversations";

export const getFBConversations = async (
  pageId: string = "",
  token: string = "",
  _id: string = ""
) => {
  try {
    FB.api(
      `/${pageId}/conversations`,
      "get",
      {
        fields:
          "id,name,updated_time,messages{story,tags,message,created_time,to,from},participants",
        platform: "instagram",
        access_token: token,
      },
      (res: any) => {
        res.data.forEach(async (conversation: any) => {
          conversation.messages.data.forEach(async (x: any, i: number) => {
            // if (x?.story) {
            const payload = {
              conversationId: x.id,
              participants: conversation.participants.data,
              from: x.from,
              to: x.to.data[0],
              pageId: _id,
              storyName: "",
              messageType: x?.story?.mention ? "story" : "text",
              message: x.message,
              media: x?.story?.mention?.link,
              mediaId: x?.story?.mention?.id,
              messageCreatedAt: x.created_time,
            };
            await addConversation(payload);
            // }
          });
        });
      }
    );
  } catch (error) {
    console.log("getFBConversations error", error);
    toast.error("token expired, please authenticate via facebook again");
  }
};

export const getTaggedPosts = async (
  _id: string,
  connectedId: string,
  pageId: string
) => {
  try {
    FB.api(
      `/${connectedId}`,
      "get",
      {
        fields:
          "username,name,biography,tags{caption,comments_count,id,like_count,media_type,permalink,thumbnail_url,timestamp,username}",
        platform: "instagram",
      },
      function (response: any) {
        if (response?.tags?.data?.length > 0) {
          response?.tags?.data.forEach(async (post: any) => {
            FB.api(
              `${connectedId}?fields=business_discovery.username(${post.username}){username,name}`,
              async (profile: any) => {
                const payload = {
                  conversationId: post.id,
                  participants: [
                    {
                      username: post.username,
                      id: profile.business_discovery.id,
                    },
                  ],
                  from: {
                    id: profile.business_discovery.id,
                    username: post.username,
                  },
                  to: {
                    id: pageId,
                    username: response.username,
                  },
                  pageId: _id,
                  storyName: "",
                  messageType: post.media_type === "VIDEO" ? "reel" : "post",
                  message: post.caption,
                  media: post.permalink,
                  mediaId: post.id,
                  messageCreatedAt: post.timestamp,
                };
                await addConversation(payload);
              }
            );
          });
        }
      }
    );
  } catch (error) {
    console.log("getTaggedPosts error", error);
  }
};

export const sendMessage = async ({
  page_id = "291074118138",
  pageId = "291074118138",
  to = "",
  username = "test",
  pageName = "me",
  message = "testing",
  token = "",
  callback = () => {},
}: {
  page_id: string;
  pageId: string;
  to: string;
  username: string;
  pageName: string;
  message: string;
  token: string;
  callback: (arg: boolean) => void;
}) => {
  try {
    FB.api(
      `/${pageId}/messages`,
      "post",
      {
        recipient: `{"id": "${to}"}`,
        message: `{"text": "${message}"}`,
        access_token: token,
      },
      async (response: any) => {
        if (!response?.error) {
          const payload = {
            conversationId: response.message_id,
            participants: [
              {
                username: username,
                id: to,
              },
            ],
            from: {
              id: pageId,
              username: pageName,
            },
            to: {
              id: to,
              username: username,
            },
            pageId: page_id,
            storyName: "",
            messageType: "text",
            message: message,
            media: "",
            mediaId: "",
            messageCreatedAt: new Date().toISOString(),
          };
          await addConversation(payload);
          callback(true);
        } else {
          callback(false);
        }
      }
    );
  } catch (error) {
    console.log("sendMessage error", error);
  }
};
