import React from "react";
import { useRouteError } from "react-router-dom";
import { Card } from "src/@/components/ui/card";

type RouteError = {
  statusText?: string;
  message?: string;
};

export const ErrorPage: React.FC = () => {
  const error = useRouteError() as RouteError | undefined;

  return (
    <div className="flex items-center justify-center w-screen h-screen">
      <Card className="p-10">
        <div id="error-page">
          <h1>Oops!</h1>
          <p>Sorry, an unexpected error has occurred.</p>
          <p>
            <i>{error?.statusText || error?.message}</i>
          </p>
        </div>
      </Card>
    </div>
  );
};
