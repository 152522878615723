import client from "./axios";
const ENV = `${process.env.REACT_APP_BACKEND_URL}/creators`;

export const getCreators = async () => {
  try {
    const creators = await client.get(`${ENV}`);
    return creators;
  } catch (error: any) {
    console.log("getCreators----", error?.message as string);
  }
};

export const addCreator = async ({
  name,
  username,
  products,
  date,
  followers,
  profilePic = "",
  id,
  ig_id,
  messageScopeId,
}: {
  name: string;
  username: string;
  products: string;
  date: string;
  followers?: number;
  profilePic?: string;
  id: string;
  ig_id: string;
  messageScopeId: string;
}) => {
  try {
    const creator = await client.post(`${ENV}/add`, {
      name,
      username,
      products,
      deliveryDate: date,
      followers,
      profilePic,
      socialId: id,
      igId: ig_id,
      messageScopeId,
    });
    return creator;
  } catch (error: any) {
    console.log("addCreator----", error?.message as string);
  }
};
