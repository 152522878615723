import * as Dialog from "@radix-ui/react-dialog";
import { Cross2Icon, MagnifyingGlassIcon } from "@radix-ui/react-icons";
import styles from "./styles.module.css";
import { useState } from "react";
import classNames from "classnames";
import { addCreator } from "src/api/creators";
import { toast } from "react-toastify";
import {
  getCreatorProfile,
  getFBCreatorConversation,
} from "src/api/conversations";

export const AddCreators = ({
  triggerText = "Add Creator",
  token,
  callback,
}: {
  triggerText?: string;
  token: string;
  callback?: () => void;
}) => {
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const [id, setId] = useState("");
  const [ig_id, setIgId] = useState("");
  const [picture, setPicture] = useState("");
  const [messageScopeId, setMessageScopeId] = useState("");
  const [followers, setFollowers] = useState(0);

  const handleSubmit = async () => {
    await addCreator({
      name,
      username,
      products: "",
      date: "",
      id,
      ig_id,
      profilePic: picture,
      followers: followers,
      messageScopeId,
    });
    toast.success("Creator added successfully");
    setName("");
    setUsername("");
    callback && callback();
  };

  const getFBCreatorProfile = async () => {
    toast.info("Fetching creator profile");
    const profile: any = await getCreatorProfile("17841401768537588", username);

    if (profile.business_discovery) {
      const messages: any = await getFBCreatorConversation({ token });
      const userConvo: any = messages.data.find((x: any) =>
        x.participants.data.find(
          (user: any) => user.username === profile.business_discovery.username
        )
      );
      setName(profile.business_discovery.name);
      setUsername(profile.business_discovery.username);
      setFollowers(profile.business_discovery.followers_count);
      setId(profile.business_discovery.id);
      setIgId(profile.business_discovery.ig_id);
      setPicture(profile.business_discovery.profile_picture_url);
      if (userConvo) {
        setMessageScopeId(
          userConvo.participants.data.find(
            (user: any) => user.username === profile.business_discovery.username
          ).id
        );
      }
    }
  };

  return (
    <Dialog.Root>
      <Dialog.Trigger asChild>
        <button className={classNames(styles["Button"], styles["primary"])}>
          {triggerText}
        </button>
      </Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay className={styles.DialogOverlay} />
        <Dialog.Content className={styles["DialogContent"]}>
          <Dialog.Title className={styles["DialogTitle"]}>
            {triggerText}
          </Dialog.Title>
          <fieldset className={styles["Fieldset"]}>
            <label className={styles["Label"]} htmlFor="username">
              Handle:
            </label>
            <input
              className={styles["Input"]}
              id="username"
              placeholder="@peduarte"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <button
              onClick={getFBCreatorProfile}
              className={classNames(styles["Button"], styles["gray"])}
            >
              <MagnifyingGlassIcon width={20} height={20} />
            </button>
          </fieldset>

          {id.length !== 0 && (
            <>
              <fieldset className={styles["Fieldset"]}>
                <label className={styles["Label"]} htmlFor="username">
                  Profile Picture:
                </label>
                <div>
                  <img width="50" height="50" src={picture} alt="dp" />
                </div>
              </fieldset>
              <fieldset className={styles["Fieldset"]}>
                <label className={styles["Label"]} htmlFor="username">
                  Name:
                </label>
                <input
                  className={styles["Input"]}
                  id="name"
                  placeholder="Pedro Duarte"
                  defaultValue={name}
                  disabled
                />
              </fieldset>
              <fieldset className={styles["Fieldset"]}>
                <label className={styles["Label"]} htmlFor="username">
                  Followers:
                </label>
                <input
                  className={styles["Input"]}
                  id="followers"
                  placeholder="1000"
                  defaultValue={followers}
                  disabled
                />
              </fieldset>
            </>
          )}
          <span
            style={{
              fontSize: 14,
              color: "gray",
            }}
          >
            Only Business Profiles and Creator Profiles allowed.
            <br />
            Note: Profile should be public
          </span>
          <div
            style={{
              display: "flex",
              marginTop: 25,
              justifyContent: "flex-end",
            }}
          >
            <Dialog.Close asChild>
              <button
                className={classNames(styles["Button"], styles["green"])}
                onClick={handleSubmit}
                disabled={!id}
              >
                Save changes
              </button>
            </Dialog.Close>
          </div>
          <Dialog.Close asChild>
            <button className={styles["IconButton"]} aria-label="Close">
              <Cross2Icon />
            </button>
          </Dialog.Close>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
