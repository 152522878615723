import axios from "axios";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const backendUrl = process.env.REACT_APP_BACKEND_URL;

export const Success: React.FC = () => {
  const navigate = useNavigate();

  const handleAuthorizationCode = async () => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const authorizationCode = urlSearchParams.get("code");
    if (authorizationCode) {
      try {
        await axios.post(`${backendUrl}auth/facebook`, {
          code: authorizationCode,
        });
        axios
          .post(`${backendUrl}auth/facebook`, {
            code: authorizationCode,
          })
          .then((res) => {
            const data = res.data;
            sessionStorage.setItem("data", data);
            sessionStorage.setItem("instaLoginStatus", "true");
            navigate("/dashboard");
          })
          .catch((err) => {
            console.log("====================================");
            console.log("err", err);
            console.log("====================================");
          });
      } catch (error) {
        console.log("Error:", error);
      }
    }
  };
  useEffect(() => {
    handleAuthorizationCode();
  });

  return <div />;
};

// {
//   "authResponse": {
//       "accessToken": "EAATLsXVhJskBOzvQT79HAAibZBilQVBdN0sVu8kg7N5ClWYqsTjxWvpZAtGg3rZC5Fh9DbknYqqz3YnEmhZBZBekQsrnracMI9q3k7UdVR5woZBBSQ0ljZCtwOb6KX308zDHikObLBI6Xa3LqnN51bZAacrYZCbZBuIoAKk3ZBKZBxPtAdi9vnrGvZAfoJZAOw4iKxFUKxWGxknhsO3ZAJFq5TTvAsZD",
//       "userID": "5793989724011491",
//       "expiresIn": 5117836,
//       "signedRequest": "T5P32R64M4JLzzmV9Jg5t69kYtwQ25NjxHgOWH76v7w.eyJ1c2VyX2lkIjoiNTc5Mzk4OTcyNDAxMTQ5MSIsImNvZGUiOiJBUUN5TktxQW5tY3MyaGpDMnpiU2l2UFoyQi1xemw2dFZBMDhTOU9LSzZtOG40eEVFcWhpa1RMMURHY0t2ZDBOZ3U3NThMcGNTRE9pYk5VTldNMFNJNENEeUVBQW13MG5GRW9TbHBDN2lJalZ1dWZRNkhoOWhjenNTRDJtenh5bnJkSDU0b2JOaVBpVjVHY20zNGJjUkdQSTJpcFZVenNoT0Q0Tm1ZX1k2RTBUMzY0VGsydkFEeU5laHBCUkZtMzZ6dVloUk11Rm1tbXRZMmtrS2hwRmwwZFlrdktqNDJHUGptNUZxOGVGOF9LYmphTFIzOEd6aWY2aXZJSVgxR0RKNEh6NWdJSVI4V2RWdUZldnQyNEtWeE50X18xUm5JSG1WSk1aeWhBb0o1T1l6bm1ic3pvd2JxQldpU3ZPZGpfcjNwdUhWZm4zeDhyQmF1YVl1VzlTYjNwbCIsIm9hdXRoX3Rva2VuIjoiRUFBVExzWFZoSnNrQk96dlFUNzlIQUFpYlpCaWxRVkJkTjBzVnU4a2c3TjVDbFdZcXNUanhXdnBaQXRHZzNyWkM1Rmg5RGJrbllxcXozWW5FbWhaQlpCZWtRc3JucmFjTUk5cTNrN1VkVlI1d29aQkJTUTBsalpDdHdPYjZLWDMwOHpESGlrT2JMQkk2WGEzTHFuTjUxYlpBYWNyWVpDYlpCdUlvQUtrM1pCS1pCeFB0QWRpOXZuckd2WkFmb0paQU93NGlLeEZVS3hXR3hrbmhzTzNaQUpGcTVUVHZBc1pEIiwiYWxnb3JpdGhtIjoiSE1BQy1TSEEyNTYiLCJpc3N1ZWRfYXQiOjE2OTY0ODc5MDB9",
//       "graphDomain": "facebook",
//       "data_access_expiration_time": 1704263900
//   },
//   "status": "connected"
// }
